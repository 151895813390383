<template>
  <div>
    <div class="flex_box">
      <div class="rate_left">
        <div ref="rateEchart" class="rate_echart"></div>
        <div class="rate_date_box">
          <div class="rate_date">信用评级时间：{{rateObj.gradeDate}}</div>
          <div class="button_show btn_down" @click="resetRate()">重新评级</div>
        </div>
      </div>
      <div class="rate_right">
        <div class="pre_item flex_center_start_box">
          <label class="pre_lbl">注册信息</label>
          <div class="progress">
            <div class="progress_bar" :style="'width:'+rateObj.registerInfo+'%;background:#339C9B;'"><span></span></div>
          </div>
          <span class="pre_num">{{rateObj.registerInfo}}%</span>
        </div>
        <div class="pre_item flex_center_start_box">
          <label class="pre_lbl">股东信息</label>
          <div class="progress">
            <div class="progress_bar" :style="'width:'+rateObj.shareholderInfo+'%;background:#339C9B;'"><span></span></div>
          </div>
          <span class="pre_num">{{rateObj.shareholderInfo}}%</span>
        </div>
        <div class="pre_item flex_center_start_box">
          <label class="pre_lbl">司法信息</label>
          <div class="progress">
            <div class="progress_bar" :style="'width:'+rateObj.judicialInfo+'%;background:#339C9B;'"><span></span></div>
          </div>
          <span class="pre_num">{{rateObj.judicialInfo}}%</span>
        </div>
        <div class="pre_item flex_center_start_box">
          <label class="pre_lbl">其他信息</label>
          <div class="progress">
            <div class="progress_bar" :style="'width:'+rateObj.otherInfo+'%;background:#339C9B;'"><span></span></div>
          </div>
          <span class="pre_num">{{rateObj.otherInfo}}%</span>
        </div>
      </div>
    </div>
    <div class="button_show m_top m_auto data_export">报告下载</div>

  </div>
</template>
<script>
import { getMyRateGrade } from "@/api/centerPerson.js";
export default {
  data() {
    return {
      rateObj: {
        riskRating: 88,
        gradeDate: '',
        registerInfo: 0,
        shareholderInfo: 0,
        judicialInfo: 0,
        otherInfo: 0,
        grade: 0
      },
      isRefresh: 0
    }
  },
  mounted() {

  },
  created() {
    this.getMyRate().then(() => {
      this.resizeFn()
    })
  },
  methods: {
    async getMyRate() {
      const res = await getMyRateGrade('isRefresh=' + this.isRefresh)
      if (res && res.code == 200) {
        this.rateObj.gradeDate = !res.gradeDate ? "0:0:0" : res.gradeDate
        this.rateObj.grade = res.grade
        this.rateObj.judicialInfo = !res.judicialInfo ? 0 : parseInt(res.judicialInfo)
        this.rateObj.otherInfo = !res.otherInfo ? 0 : parseInt(res.otherInfo)
        this.rateObj.shareholderInfo = !res.shareholderInfo ? 0 : parseInt(res.shareholderInfo)
        this.rateObj.registerInfo = !res.registerInfo ? 0 : parseInt(res.registerInfo)
      } else {
        this.$message.error(res.msg)
      }
    },
    //重新评级
    resetRate() {
      this.isRefresh = 1
      this.getMyRate()
    },
    rateEcharts(fontSize, laFontSize, titFontSize, legWidth, axLineWidth, legHeight) {
      let myChart = this.$echarts.getInstanceByDom(this.$refs.rateEchart);
      if (myChart == null) {
        myChart = this.$echarts.init(this.$refs.rateEchart);
      }
      console.log(this.rateObj.grade)
      const levelList = { '--': 0, 'C': 10, 'CC': 17, 'CCC': 26, 'B-': 33, 'B': 40, 'B+': 48, 'BB-': 54, 'BB': 58, 'BB+': 64, 'BBB-': 69, 'BBB': 74, 'BBB+': 78, 'A-': 82, 'A': 86, 'A+': 89, 'AA-': 92, 'AA': 95, 'AA+': 98, 'AAA': 100 }
      let score = levelList[this.rateObj.grade]
      const gaugeData = [
        {
          value: score,
          name: '等 级',
          title: {
            offsetCenter: ['0%', '-16%'],
            fontSize: fontSize,
            color: "#92A2BC"
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '10%'],
            fontSize: laFontSize,
            color: " #162747"
          },
          itemStyle: {//圆环内进度条颜色
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0, color: 'rgba(112, 215, 214, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(51, 156, 155, 1)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            }
          },
        }
      ];
      const option = {
        series: [
          {
            center: ['39%', '39%'],
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {//进度条阴影
                shadowColor: 'rgba(18, 92, 91, 0.44)',
                shadowBlur: 6,
                shadowOffsetX: -2
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: axLineWidth,
                color: [[1, '#E0E5E7']],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 0
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50
            },

            data: gaugeData,
            title: {
              fontSize: titFontSize
            },
            detail: {
              width: legWidth,
              height: legHeight,
              fontSize: titFontSize,
              color: 'auto',
              //formatter: this.rateObj.grade
              formatter: score > 0 ? this.rateObj.grade : '--',
            }
          },
          //圆环内背景
          {
            name: 'gauge 5',
            type: 'gauge',
            min: 0,
            max: 1,
            startAngle: 360,
            endAngle: -90,
            splitNumber: 1,
            radius: '41%',
            center: ['39%', '39%'],
            roundCap: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,

            },
            axisLabel: {
              show: false,
            },
            progress: {
              show: true,
              width: 1,
              itemStyle: {
                color: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [{
                    offset: 0, color: 'rgba(221, 228, 232, 0.2)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(221, 228, 232, .2)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                },
                shadowColor: 'rgba(18, 92, 91, 0.31)',
                shadowBlur: 28,
                shadowOffset: -8
              }
            },

            pointer: {
              show: false
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ['0%', '20%'],
              fontSize: laFontSize,
              color: "transparent"
            },
            data: [
              {
                value: 1,
                name: '',
              }
            ]
          },
        ]
      };
      myChart.setOption(option);
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 16;
        this.laFontSize = 30
        this.titFontSize = 14
        this.legWidth = 50
        this.axLineWidth = 62
        this.legHeight = 14
      } else {
        this.fontSize = 12;
        this.laFontSize = 20
        this.titFontSize = 10
        this.legWidth = 33
        this.axLineWidth = 41
        this.legHeight = 9
      }
      this.$nextTick(() => {
        this.rateEcharts(this.fontSize, this.laFontSize, this.titFontSize, this.legWidth, this.axLineWidth, this.legHeight)
      })
    },
  }
}
</script>
<style scoped>
  .rate_left {
    width: 340px;
    margin-right: 20px;
  }
  .rate_echart {
    width: 370px;
    height: 370px;
  }
  .rate_date {
    text-align: center;
    font-size: 16px;
    color: #92a2bc;
    line-height: 21px;
  }
  .rate_right {
    width: 100%;
    height: 332px;
    padding: 40px 30px;
    background: #edf2f5;
    box-shadow: 0px 0px 5px 0px rgba(9, 44, 55, 0.28) inset;
    border-radius: 24px;
  }
  .pre_item {
    margin-bottom: 52px;
  }
  .progress {
    width: 912px;
    height: 10px;
    background: #f7fafc;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.14) inset;
    border-radius: 5px;
    margin: 0 40px 0 20px;
  }
  .progress .progress_bar {
    position: relative;
    border-radius: 5px 0 0 5px;
  }
  .progress_bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #a11e1e;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
    -webkit-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }
  .progress .progress_bar span {
    position: absolute;
    top: -6px;
    right: -16px;
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #ffffff;
    box-shadow: 0px 1px 5px 0px rgba(45, 130, 130, 0.34);
  }
  .pre_lbl {
    font-size: 16px;
    color: #162747;
    line-height: 21px;
  }
  .pre_num {
    font-size: 16px;
    color: #339c9b;
    line-height: 21px;
  }
  .m_auto {
    margin-left: auto;
    margin-right: 0;
    margin-top: -36px;
  }
  .btn_down {
    margin: 40px auto 0;
  }
  .rate_date_box {
    margin-top: -70px;
    margin-left: -58px;
  }
  @media screen and (max-width: 1664px) {
    .rate_left {
      width: 224px;
      margin-right: 13px;
    }
    .rate_echart {
      width: 244px;
      height: 244px;
    }
    .rate_date {
      font-size: 12px;
      line-height: 14px;
    }
    .rate_right {
      height: 219px;
      padding: 26px 20px;
      box-shadow: 0px 0px 3px 0px rgba(9, 44, 55, 0.28) inset;
      border-radius: 16px;
    }
    .rate_date_box {
      margin-top: -46px;
      margin-left: -38px;
    }
    .btn_down {
      margin: 26px auto 0;
    }
    .pre_item {
      margin-bottom: 34px;
    }
    .pre_num,
    .pre_lbl {
      font-size: 12px;
      line-height: 14px;
    }
    .progress {
      width: 592px;
      height: 7px;
      border-radius: 3px;
      margin: 0 26px 0 13px;
    }
    .progress .progress_bar span {
      position: absolute;
      top: -4px;
      right: -12px;
      width: 13px;
      height: 13px;
      box-shadow: 0px 1px 3px 0px rgb(45 130 130 / 34%);
    }
    .m_auto {
      margin-left: auto;
      margin-top: -24px;
    }
  }
</style>